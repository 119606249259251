import React, { Component } from "react"
import { css } from "@emotion/react"

import { graphql } from "gatsby"
import * as Organisms from "../components/organisms"
import * as Libs from "libs"
class Page extends Component {
  //--------------------------------------
  //  Styless
  //--------------------------------------
  sectionCss = () =>
    css`
      ${Libs.Styles.Mixin.containerCss()}
    `

  //--------------------------------------
  //  Scripts
  //--------------------------------------

  constructor(props) {
    super(props)

    this.init = true
    //Transition.InitTransition.InitTransition(Common.Config.getDispatcher)
  }
  shouldComponentUpdate(nextProps, nextState) {
    return false
  }
  componentWillMount() {}
  componentDidMount() {
    if (this.init) this.init = false
    Libs.Common.Config.excuteTransition()
  }
  componentWillUnmount() {}

  getElement(page) {
    if (page.slug === "index") {
      return (
        <Organisms.IndexPage
          feature={page.feature}
          body={page.body}
          data-type={"page"}
          className={"project-container"}
        />
      )
    } else if (page.slug === "howltcoffee") {
      return (
        <Organisms.HowltCoffeePage
          feature={page.feature}
          lang={page.node_locale}
          body={page.body}
          data-type={"page"}
          className={"project-container"}
        />
      )
    } else if (page.slug === "about") {
      return (
        <Organisms.AboutPage
          feature={page.feature}
          body={page.body}
          className={"project-container"}
        />
      )
    } else if (page.slug === "contact") {
      return (
        <Organisms.ContactPage
          feature={page.feature}
          body={page.body}
          className={"project-container"}
        />
      )
    } else if (page.slug === "shopping-info") {
      return (
        <Organisms.PolicyPage
          feature={page.feature}
          body={page.body}
          className={"project-container"}
        />
      )
    } else if (page.slug === "privacy-policy") {
      return (
        <Organisms.PolicyPage
          feature={page.feature}
          body={page.body}
          className={"project-container"}
        />
      )
    }
  }
  render() {
    const { data } = this.props
    const page = data.contentfulPages
    const element = this.getElement(page)
    let schemaOrgJSONLD = {
      index: [
        {
          "@context": "http://schema.org",
          "@id": "https://howlt.com/#organization",
          "@type": "Organization",
          name: "Howlt",
          url: "https://howlt.com/",
          logo: {
            "@type": "ImageObject",
            "@id": "https://howlt.com/#logo",
            url: "https://howlt.com/howlt-logo.jpg",
            caption: "Howlt",
          },
          sameAs: [
            "https://www.instagram.com/howlt",
            "https://www.facebook.com/howlt",
            "https://www.twitter.com/howlt",
          ],
        },
      ],
    }
    if (page.slug !== "index") {
      schemaOrgJSONLD[page.slug] = [
        Libs.Common.SEO.getBreadcrumbSchemaOrgJSONLD([
          { name: "Home", url: `/${page.node_locale}/` },
          {
            name: page.name,
            title: page.title,
            url: `/${page.node_locale}/${page.slug}/`,
          },
        ]),
      ]
    } else {
      schemaOrgJSONLD[page.slug] = [
        Libs.Common.SEO.getBreadcrumbSchemaOrgJSONLD([
          { name: "Home", url: `/${page.node_locale}/` },
        ]),
      ]
    }

    return (
      <>
        <Libs.Common.SEO.SEO
          title={page.name !== "Index" ? page.title : ""}
          description={page.description ? page.description.description : null}
          lang={page.node_locale}
          schemaOrgJSONLD={schemaOrgJSONLD[page.slug]}
          link={page.slug}
        />
        {element}
      </>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query pageQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulPages(id: { eq: $id }) {
      node_locale
      slug
      title
      name
      description {
        description
      }
      feature {
        ...LayoutCard
      }

      body {
        ... on ContentfulElementLayoutCard {
          ...LayoutCard
        }
        ... on ContentfulElementFlexColumn {
          ...FlexColumn
        }
        ... on ContentfulElementReference {
          ...Reference
        }
        ... on ContentfulElementBlock {
          ...Block
        }
        ... on ContentfulElementTextField {
          ...TextField
        }
      }
    }
  }
`
